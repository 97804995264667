<template>
    <div class="mx-3">
        <h2 class="px-5 py-5">{{ $t('Total Sessions Report') }}</h2>
        <v-row class="px-10 py-1">
            <v-col md="3">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date" v-model="filters.from" clearable>
                </v-text-field>
            </v-col>
            <v-col md="3">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date" v-model="filters.to" clearable>
                </v-text-field>
            </v-col>
            <v-col md="3">
                <AutoCompleteField :label="$t('teacher name')" v-model="filters.teacher" clearable
                    endPoint="/teacher/auto-complete" />
            </v-col>
            <v-col md="3">
                <AutoCompleteField :label="$t('subject')" v-model="filters.subject" clearable
                    endPoint="/subject" />
            </v-col>
            <v-col md="3">
                <AutoCompleteField :label="$t('educitional class')" v-model="filters.edu_class" clearable
                    endPoint="/edu-class" />
            </v-col>
            <v-col md="3">
                <AutoCompleteField :label="$t('class')" v-model="filters.grade" clearable
                    endPoint="/grade" />
            </v-col>
            <v-col md="3">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('Lesson Number')" type="number" v-model="filters.lesson_number" clearable>
                </v-text-field>
            </v-col>
            <v-col md="3">
            </v-col>
            <v-col md="3" class="mb-3 py-0">
                <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
            </v-col>
            <v-col md="3" class="mb-3 py-0">
                <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
            </v-col>
            <v-col md="3" class="mb-3 py-0" v-if="returnAbility('engagement:responsibility:export')">
                <ExportExcel end-point="/ai/engagment/export" :params="filters" />
            </v-col>
        </v-row>
        <v-container id="regular-tables" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode">
                <thead class="table-heading" v-if="!loading">
                    <tr>
                        <th v-for="(name, i) in tableHeaders" :key="i">
                            {{ $t(name) }}
                        </th>
                    </tr>
                </thead>
                <div class="table__spinnerWrapper" v-if="loading">
                    <app-spinner></app-spinner>
                </div>
                <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!loading">
                        <tr v-for="(item, idx) in tableItems" :key="idx">
                            <td>{{ item.date }}</td>
                            <td>{{ item.teacher }}</td>
                            <td>{{ item.edu_class }}</td>
                            <td>{{ item.grade }}</td>
                            <td>{{ item.subject }}</td>
                            <td>{{ item.order }}</td>
                            <td>{{ item.engagement }}%</td>
                          
                           
                        </tr>
                </v-fade-transition>
            </v-simple-table>
            <div class="d-flex justify-end mt-5">
                <v-row>
                    <v-col md="5 ">
                        <v-switch :dark="$store.state.isDarkMode" @change="goToAll" v-model="is_all" color="green" :label="$t('Show All')"></v-switch>
                    </v-col>
                    <app-pagination v-if="(!loading & (paginated))" :disable-pagination="!paginated" :totalPages="total"
                        :page="page" @PaginationValue="applyPagination($event)"></app-pagination>
                </v-row>
            </div>
        </v-container>
    </div>
</template>
  
<script>
import axios from 'axios'
import spinner from "../../../../../components/base/spinner.vue";
import pagination from "../../../../../components/base/pagination.vue";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";
import ExportExcel from "../../../components/core/ExportExcel.vue";
import i18n from '../../../../../i18n';

export default {
    components: {
        appSpinner: spinner,
        appPagination: pagination,
        AutoCompleteField,
        ExportExcel,
    },
    data: () => ({
        is_all: false,
        paginated: true,
        tableItems: [],
        loading: false,
        page: 1,
        total: 1,
        tableHeaders: ["date", "teacher name","class", "educitional class", "subject", "Lesson Number","Participate"],
        filters: {
            teacher: null,
            edu_class: null,
            grade: null,
            from: null,
            to: null,
            subject: null,
            lesson_number: null,
        },
    }),
    methods: {
        goToAll() {
            if (this.is_all) {
                this.paginated = false;
            } else { this.paginated = true; }
            this.getAll();
        },
        clearFilter() {
            this.motion = [];
            this.filters.teacher = undefined;
            this.filters.edu_class = undefined;
            this.filters.from = undefined;
            this.filters.to = undefined;
            this.filters.grade = undefined;
            this.filters.subject = undefined;
            this.filters.lesson_number = undefined;
            this.getAll();
        },
        async getAll() {
            try {
                const filterTemp = {};
                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key]) filterTemp[key] = this.filters[key];
                });
                this.loading = true;
                const res = await axios.get("/ai/engagment/report", {
                    params: {
                        size: 10,
                        page: this.page,
                        paginated: this.paginated,
                        ...filterTemp,
                    },
                });
                const arr = Array.from(res.data.data)
                if (Array.isArray(arr)) {
                    console.log(arr);
                    this.tableItems = arr.map((el) => {
                        return {
                            ...el,
                        };
                    });
                }
                this.total = res.data.meta.total;
                this.page = res.data.meta.current_page;
            } finally {
                this.loading = false;
            }
        },
        applyPagination(paginationValue) {
            this.page = paginationValue;
            this.getAll();
        },
    },
    async created() {
        this.getAll();
    },
};
</script>
  
<style></style>
  